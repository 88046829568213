/**
 * Basic typography style for text
 * Warning: These styles overrides without the default .dw-mod class, as they all target the most basic required elements
 */
/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*
Note: The best way to structure the use of media queries is to create the queries
near the relevant code. For example, if you wanted to change the styles for buttons
on small devices, paste the mobile query code up in the buttons section and style it
there.
*/
.product__info .carousel__slide .modal--full__img {
  max-width: 100%;
}
